@mixin link-style(
    $link-color: $link-colour,
    $border-width: null,
    $border-style: null,
    $border-color: null,
    $border-top-width: null,
    $border-top-style: null,
    $border-top-color: null,
    $border-right-width: null,
    $border-right-style: null,
    $border-right-color: null,
    $border-bottom-width: null,
    $border-bottom-style: null,
    $border-bottom-color: null,
    $border-left-width: null,
    $border-left-style: null,
    $border-left-color: null
) {
    color: $link-color;
    text-decoration: none;

    @if $border-width {
        border: $border-width $border-style $border-color;
    } @else {
        border-top: $border-top-width $border-top-style $border-top-color;
        border-right: $border-right-width $border-right-style $border-right-color;
        border-bottom: $border-bottom-width $border-bottom-style $border-bottom-color;
        border-left: $border-left-width $border-left-style $border-left-color;
    }
}

@mixin link-hover-style($hover-color: $hover-colour) {
    &:hover {
        color: $hover-color;
    }
}

@mixin link-border-style($border-color: $border-colour, $border-style: solid) {
    border-bottom: 1px $border-style $border-color;
}

@mixin typography(
    $font-family: $body-font,
    $font-weight: normal,
    $font-size: calc(#{$base-font-size} + 0.390625vw),
    $height-key: 'body',
    $font-style: normal,
    $text-transform: none,
    $font-variant-caps: normal,
) {
    font-family: $font-family;
    font-weight: $font-weight;
    font-size: $font-size;
    line-height: fluid-line-height($height-key);
    font-style: $font-style;
    text-transform: $text-transform;
    font-variant-caps: $font-variant-caps;
}
