/* import */

@import "fonts";
@import "colours";
@import "line-heights";
@import "mixins";

/* variables */

$body-font: "Kings Caslon Regular", serif;
$body-font-bold: "Kings Caslon Bold", serif;
$body-font-italic: "Kings Caslon Italic", serif;
$body-font-bdit: "Kings Caslon Bold Italic", serif;
$display: "Irvin Heading", serif;
$sans-serif: "Neutra Text Book", sans-serif;
$sans-serif-italic: "Neutra Text Book Italic", sans-serif;
$toc: "Neutra Text Book", sans-serif;
$monospace: "Courier Std", monospace;
$caption: "Neutra Text Light Italic", serif;
$super: "ACaslonExp Regular", serif;
$logotype: "American Typewriter", monospace;

$font-bold: 700;
$font-semibold: 600;
$base-font-size: 16px;

/* base features */

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
    line-height: fluid-line-height('body');
	font-size: calc(#{$base-font-size} + 0.390625vw);
	font-family: $body-font;
    hyphens: auto;
    text-align: justify;
}

body {
	max-width: 75ch;
	--body-width: 75ch;
	margin: 0 auto;
	padding: 0.5em;
	background-color: $background-colour;
	color: $text-colour;
}

main {
	position: relative;
    /*max-width: var(--GW-body-max-width);*/
}

a {
  @include link-style(
    $border-bottom-width: 1px,
    $border-bottom-style: solid,
    $border-bottom-color: $border-colour
    );
  @include link-hover-style;
}

#sidebar {
	display: flex;
	justify-content: center;
    @include typography($display);
}

	#sidebar a#logo {
        @include link-style(
          $border-width: 1px,
          $border-style: dotted,
          $border-color: $border-colour,
          $link-color: inherit
          );
        @include link-border-style($border-color: $hover-colour, $border-style: dotted);
		margin: 1px;
		padding: 0.25rem 0.75rem;
		align-content: center;
        @include link-hover-style;
	}

	#logo img {
		min-width: 3em;
		flex: 1 1 100%;
		filter: invert(11%) sepia(6%) saturate(187%) hue-rotate(22deg) brightness(105%) contrast(87%); /* $text-colour */
		/* filter: invert(98%) sepia(18%) saturate(553%) hue-rotate(346deg) brightness(111%) contrast(105%); */ /* $background-colour */
	}

    #sidebar span#logotype {
        @include typography($logotype, normal, 2em);
      }

	.sidebar-links {
		flex: 1 1 100%;
		display: flex;
		flex-flow: row wrap;
	}

	#sidebar a {
		display: block;
		text-align: center;
		font-variant: small-caps;
        @include link-style($border-color: $hover-colour, $link-color: inherit);
        @include link-hover-style;
	}

	#sidebar .sidebar-links a {
		flex: 1 1 33%;
		padding: 0.25rem 0.75rem;
		align-content: center;
        @include link-style(
          $border-width: 1px,
          $border-style: dotted,
          $border-color: $border-colour,
          $link-color: inherit
    );
	}

	#sidebar .sidebar-links a.patreon {
		display: block;
		text-align: center;
		font-variant: small-caps;
        color: $highlight-colour;
        @include link-style($border-color: $highlight-colour, $link-color: $highlight-colour);
        @include link-hover-style($highlight-colour-secondary);
	}

aside.index.abstract {
	margin: 0 auto;
}

#markdownBody {
	line-height: fluid-line-height('article');
/*	display: flex;
	flex-flow: row wrap;*/
	margin: 1em auto;
	max-width: 75ch;
}

	#markdownBody .section-link-list {
		padding: 0 0 0 1.25em;
		margin: 1em 0;
	}

		#markdownBody .section-link-list li {
			line-height: fluid-line-height('list');
			padding: 0.1em;
            list-style-position: outside;
            margin: 0;
		}

	#markdownBody #diaries .section-link-list li {
		list-style-type: square;
	}

	.markdownBody a {
        @include link-style($link-color: $text-colour);
        @include link-hover-style;
		/*color: $text-colour;*/
		font-variant-numeric: lining-nums;
	}

    .markdownBody li {
        list-style-position: outside;
    }

	p + p {
		text-indent: 2.25em;
		margin-top: 0;
	}

a.footnote-ref {
	vertical-align: super;
	line-height: 1;
	position: relative;
	top: 0.1em;
	padding: 0 0.1em;
	margin-left: 0.25em;
	font-variant-numeric: lining-nums;
	font-weight: $font-semibold;
	z-index: 1;
}

.footnote-back {
	padding: 0 0.1em;
	margin-left: 0.1em;
	font-weight: $font-semibold;
}

h1.index-heading {
	border-bottom: 1px solid $hover-colour;
	margin: 1.125em 0 0 0;
	text-align: left;
	padding: 0;
    @include typography($display, normal, 1.5rem, display, normal, none, small-caps);
    a {
      border-bottom: none;
    }
}

hr.index-section-ornament {
	order: 4;
	border: 0;
	display: flex;
	margin: 0;
	width: 100%;
}

hr.index-section-ornament::after {
	content: "";
	margin: auto;
	width: 2.5em;
	height: 2.5em;
	background-repeat: no-repeat;
	background-position: center;
	background-origin: content-box;
	background-size: 2.5em;
	background-image: url('/assets/images/caslon-section-ornament.svg');
}

/*section > :first-child > a {
	border-bottom: none;
}*/

footer {
	text-align: center;
	margin: 3em 0 1.5em 0;
	align-items: center;
	display: flex;
	flex-flow: column;
}

.feedback a {
    @include link-style;
    @include link-hover-style;
}

.end-ornament {
	max-width: 75%;
	padding-bottom: 1.25em;
}

.epitaph {
	max-width: 75ch;
	font-style: italic;
}

#footer-logo {
        @include link-style($border-width: none);
		margin: 3em 0 0 0;
		align-content: center;
        @include link-hover-style;
	}

	#footer-logo img {
		min-width: 3em;
		filter: invert(11%) sepia(6%) saturate(187%) hue-rotate(22deg) brightness(105%) contrast(87%); /* $text-colour */
		/* filter: invert(98%) sepia(18%) saturate(553%) hue-rotate(346deg) brightness(111%) contrast(105%); */ /* $background-colour */
}

/* post layout */

.post section {
	margin-top: 2.75em;
	}

#page-metadata {
	margin: 0 0 2.25rem 0;
	text-align: center;
    @include typography($sans-serif, normal, null, 1.5em);
}

#page-description {
	margin: 0 auto 1em auto;
	// font-style: italic;
	text-align: center;
    @include typography($body-font, inherit, inherit, body, italic);
}

div#link-tags {
	margin: 0 auto 1em auto;
	text-align: center;
    @include typography($sans-serif);
}

span.page-status {
	font-style: italic;
}

#TOC {
	border: 1px solid $secondary-border-colour;
	background-color: $secondary-background-colour;
	z-index: 20;
	padding: 1em;
    @include typography($toc, normal, 1rem, 'display');
    text-align: left;
}

.section-nav a {
    @include link-style($border-color: none, $link-color: $secondary-text-colour);
    @include link-hover-style($hover-color: $secondary-hover-colour);
}

/*
.blog-post-header {
	padding-bottom: 1em;
}

.blog-post-date {
    line-height: 1.60;
	font-size: 18px;
	font-weight: 700;
	display: inline;
}

	.blog-post-date::before {
		content: "§";
		font-style: normal;
		font-weight: bold;
	    color: #111;
	    text-decoration: none;
	}

.blog-post-title {
	font-size: 18px;
	font-weight: 700;
	font-variant: small-caps;
}

	.blog-post-title::before {
		content: "—";
		font-style: normal;
	    text-decoration: none;
		padding: 0.5rem;
		font-size: 18px;
		font-weight: 700;
	}

.blog-post-modified-date {
	display: inline;
	font-style: italic;
}

	.blog-post-modified-date::before {
		content: "(";
	    text-decoration: none;
	}

	.blog-post-modified-date::after {
		content: ".)";
	    text-decoration: none;
	}

.blog-post-description {
	font-style: italic;
}

	.blog-post-description::before {
		content: "—";
		font-style: normal;
	    text-decoration: none;
		padding: 0.5rem;
		font-size: 18px;
		font-weight: 700;
	}
*/

/* blockquotes */

blockquote {
	margin: 1em 0 1.5em 0;
	padding: 1rem 1.25rem 1rem 1.25rem;
	border: 1px solid $secondary-border-colour;
	background-color: $secondary-background-colour;
	/*overflow: hidden;*/
    display: flow-root;
}

code {
	margin: 1em 0 1.5em 0;
	padding: 1rem 1.25rem 1rem 1.25rem;
	border: 1px solid $secondary-border-colour;
	background-color: $secondary-background-colour;
	/*overflow: hidden;*/
    display: flow-root;
    text-wrap: wrap;
}

.abstract blockquote {
	margin: 0 0 1.5em 0;
	padding-top: 1em;
	border: 1px solid $secondary-border-colour;
	background-color: $secondary-background-colour;
	font-family: $sans-serif;
    em {
      font-family: $sans-serif-italic;
    }
    strong {
      font-family: $sans-serif;
      font-weight: $font-bold;
    }
}

.epigraph {
	padding: 0.75em 2.5em;
	background-color: $background-colour;
	border: none;
	font-family: "Source Serif 4";
}

	.epigraph p {
		font-style: italic;
	}

	.epigraph p:last-child:not(:only-child) {
		margin: 0.5em 0 0 0;
		text-align: right;
		font-size: 0.75em;
		font-style: normal;
	}

	.epigraph::before {
		display: block;
		position: absolute;
		font-size: 2.5em;
		content: "\201c";
		left: -0.01em;
	}

	.epigraph::after {
		display: block;
		position: absolute;
		font-size: 2.5em;
		content: "\201D";
		right: -0.01em;
	}

/* images */

figure {
	margin: 1em auto;
    width: 100%;
}

figcaption {
	text-align: center;
	font-size: 0.85em;
    font-family: $caption;
    line-height: scale-line-height('list', 1.25);
    padding: 0.5rem;
    em {
      font-family: inherit;
      font-size: inherit;
      font-style: inherit;
      line-height: inherit;
    }
}

span.citation {
  font-style: italic ;
}

img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
}

figure img {
    border: 1px solid $border-colour;
    box-shadow: 0 0 10px 0 $border-colour; }

img:hover {
}

/* lists */

ol {
	counter-reset: count;
	list-style-type: decimal;
}

	ol li::marker {
		content: counters(count, ".", decimal) ". ";
	}

li {
	counter-increment: count;
	padding: 0 0 0.65em 0;
    margin-left: 2.25em;
}

/* TOC */

#TOC > ul {
	column-count: 2;
	margin: 0;
	column-gap: 2em;
	list-style-type: none;
	padding: 0;
}

#TOC a {
  @include link-style($link-color: $secondary-text-colour);
  border-bottom: none;

  &:hover {
    color: $secondary-hover-colour;
    background-color: $secondary-background-colour;
  }
}

.section-nav > ul {
	counter-reset: htoc_1;
	list-style-type: none;
}

.section-nav ul {
	text-indent: 0;
	padding: 0;
	overflow: unset;
	list-style-type: none;
	padding-left: 0.5em;
	padding-top: 0.25em;
}

.section-nav li {
	font-weight: $font-bold;
	position: relative;
	overflow-wrap: break-word;
	font-size: 0.9em;
    margin: 0;
}

.section-nav li li {
	font-weight: normal;
    padding: 0;
}

/* typography */

strong {
  @include typography($body-font-bold);
}

sup {
  @include typography($super, normal, null, article);
  line-height: 1;
}

em {
  @include typography($body-font-italic);
}

h1 {
  @include typography($display, $font-bold);
}

h2 {
  @include typography($display, $font-semibold);
}

h1.title {
	margin: 0.75em 0;
	text-align: center;
	letter-spacing: -1px;
	border-bottom: none;
    @include typography($display, normal, 2.5em, display, normal, none, small-caps);
}

section.level1 > h1 {
	border-bottom: 1px solid $hover-colour;
	text-align: right;
	padding: 0;
	margin-bottom: 1em;
	letter-spacing: -0.75px;
	position: relative;
    @include typography($display, $font-bold, 1.375rem, display, normal, none, small-caps);
    a {
      border-bottom: none;
    }
}

section.level1 > h1.diary {
	text-align: center;
	border: none;
	margin-bottom: 0.5em;
}

section.level1 hr {
    overflow: visible; /* For IE */
    height: 30px;
    border-style: solid;
    border-color: black;
    border-width: 1px 0 0 0;
    border-radius: 20px;
	margin: 0.5em 0 0 0;
	padding: 0;
}

section.level1 hr:before { /* Not really supposed to work, but does */
    display: block;
    content: "";
    height: 30px;
    margin-top: -31px;
    border-style: solid;
    border-color: black;
    border-width: 0 0 1px 0;
    border-radius: 20px;
}

section.level2 hr {
	border-style: none;
	border-width: 0;
	border-radius: 0;
	margin: 0.5em 0 0 0;
	padding: 0;
}

section.level2 hr:before { /* Not really supposed to work, but does */
    display: block;
    content: "\01F7BB \01F7BE \01F7BB";
	text-align: center;
	height: 0;
	margin-top: 0;
	border-style: none;
	border-color: none;
	border-width: 0;
	border-radius: 0;
}

#markdownBody h2.chapter-subsection {
	text-align: center;
	font-style: italic;
	text-transform: capitalize;
	font-variant: normal;
	border: none;
	margin-bottom: 0.75em;
}

#markdownBody section > h1 a::after {
	right: unset;
	left: 0;
}

h1 a::after:not(diary) {
	content: "\00B6";
	font-size: 0.75em;
	position: absolute;
    color: $hover-colour;
    text-decoration: none;
	bottom: 0.15em;
	right: 0;
	opacity: 0.5;
}

h1 a::after {
	right: unset;
	left: 0;
}

h1 a, h2 a {
    @include link-style($border-color: none);
	}

section.level2 > h2 {
	border-bottom: 1px dotted $border-colour;
	text-align: left;
	position: relative;
	padding: 0;
	margin: 0;
    @include typography($display, $font-semibold, 1.15em, 1.15, normal, uppercase, small-caps);
    a {
      border-bottom: none;
    }
}

h2 a::after {
	content: "\00B6";
	font-size: 0.75em;
	position: absolute;
    color: $text-colour;
    text-decoration: none;
	bottom: 0.15em;
	right: 0;
	opacity: 0.5;
}

h3 {
	position: relative;
    @include typography($display, bold, 1.25em, null, normal, capitalize, small-caps);
}

div.csl-entry {
	padding-top: 0.75em;
	text-indent: 2.25em;
	}

section#footnotes {
	margin-top: 3.5em;
	}

section#footnotes hr {
    overflow: visible; /* For IE */
    height: 30px;
    border-style: solid;
    border-color: $hover-colour;
    border-width: 1px 0 0 0;
    border-radius: 20px;
}

section#footnotes hr:before { /* Not really supposed to work, but does */
    display: block;
    content: "";
    height: 30px;
    margin-top: -31px;
    border-style: solid;
    border-color: $hover-colour;
    border-width: 0 0 1px 0;
    border-radius: 20px;
}

section#footnotes li {
}


@media (min-width: 650px) {
	/* introduce column-count: 2; sections around 1k pixels */

	main {
		min-height: 100vh;
		display: flex;
		flex-flow: column;
	}

	article {
		flex: 1 1 auto;
	}

	header {
		padding: 0 2em;
		margin: 2.125rem 0 1.75rem 0;
	}

	#sidebar .sidebar-links a {
		text-transform: uppercase;
		font-weight: $font-semibold;
		flex: 1 1 auto;
        @include link-style($link-color: $hover-colour);
        @include link-hover-style($hover-color: $secondary-hover-colour);
	}
}

@media (min-width: 901px) {

	body.page-index #markdownBody {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 2em;
		grid-row-gap: 0.5em;
	}

	article.post {
		max-width: 75ch;
		margin: 0 auto;
	}

	hr.index-section-ornament {
		grid-column: 1 / -1;
		order: unset;
	}

	#TOC {
		max-width: 285px;
		margin: 0 1.5rem 0 0;
		float: left;
        position: relative;
        box-shadow: -1rem 0 0 0 $background-colour, 1.5rem 0 0 0 $background-colour;
	}

	#TOC > ul {
		column-count: 1;
	}

	#TOC li {
		padding: 0;
	}

	.section-nav ul {
		margin-top: 0.25em;
		text-indent: 0;
		padding-left: 0.75em;
		padding-top: 0;
	}

	aside.index.abstract {
      grid-column: 1 / -1;
	}
}

@media (min-width: 1041px) {
	main {
	}

	#sidebar {
	}

	#logo {
	}

	.sidebar-links {
	}

	article {
	}
	/*aside.index.abstract {
		grid-column: 1 / -1;
		justify-self: center;
	}

	#markdownBody {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 2em;
	}*/

	/*#markdownBody section {
		width: 37ch;
	}

	#markdownBody section:nth-child(even) {
		margin: 0 1ch 0 0;
	}*/
}

@media (min-width: 1180px) {

    #sidebar {
    }

	#sidebar a#logo {
		border: none;
	}

	#sidebar #logo img {
		width: 5em;
	}
}

@media (min-width: 1761px ) {
	body.page-index #markdownBody {
		grid-template-columns: 35ch 35ch 35ch;
		margin-left: -20ch;
	}

    #markdownBody {
        position: relative;
      }

	/*#markdownBody section:nth-last-child(-n+2) {
		margin-left: auto;
	}*/

	/*#markdownBody {
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: 2em;
		grid-row-gap: 0.5em;
		position: relative;
	}

	#markdownBody > * {
		display: grid;
		grid: subgrid / subgrid;
		grid-template-rows: subgrid;
		grid-template-columns: subgrid;*/

	aside.index.abstract {
		margin-left: 33.25ch;
		max-width: 59.90ch;
	}

}

@media (max-width: 1760px ) {
  #sidenote-column-left,
  #sidenote-column-right {
    display: none;
  }
}

/*************/
/* SIDENOTES */
/*************/

.sidenote-layout-cell {
    position: absolute;
    width: 100%;
    pointer-events: none;
}
.sidenote-layout-cell > * {
    pointer-events: auto;
}

#sidenote-column-left,
#sidenote-column-right,
#hidden-sidenote-storage {
    width: calc(50vw - (935px / 2 + 96px));
    max-width: 935px;
}

#sidenote-column-left,
#sidenote-column-right {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 1;
    /*pointer-events: none;*/
}
#sidenote-column-left {
    right: calc(100% + 64px);
}
#sidenote-column-right {
    left: calc(100% + 64px);
}
#sidenote-column-left:empty,
#sidenote-column-right:empty {
    display: none;
}

/*=-----------------=*/
/*= Sidenote blocks =*/
/*=-----------------=*/

#markdownBody .sidenote {
    --sidenote-padding: 10px;
    --sidenote-border-width: 3px;
    --text-indent: 1.75em;

    position: absolute;
    left: 0;
    width: 100%;
    opacity: 0.85;
    padding: 2em 0 0 0;
    font-size: 0.85em;
    line-height: calc(1.3 / 0.85);
    transition:
        opacity 0.25s ease-in,
        transform 0.25s ease-in;
}
#markdownBody .sidenote.displaced {
    transition:
        opacity 0.25s ease-out,
        transform 0.25s ease-out;
}
#markdownBody .sidenote.hidden {
    display: none;
}
#markdownBody .sidenote:hover,
#markdownBody .sidenote.targeted,
#markdownBody .sidenote.displaced,
#markdownBody .sidenote.highlighted {
    background-color: $background-colour;
    opacity: 1.0;
    box-shadow: 0 0 0 16px $background-colour;
}
#markdownBody .sidenote.targeted {
    z-index: 1;
}
#markdownBody .sidenote.displaced {
    z-index: 2;
}
#markdownBody .sidenote.highlighted,
#markdownBody .sidenote:hover {
    z-index: 3;
}

/*  This provides a solid hover margin.
    */
#markdownBody .sidenote::after {
    content: "";
    position: absolute;
    width: calc(100% + 2 * var(--sidenote-padding));
    height: calc(100% + 2 * var(--sidenote-padding));
    top: calc(-1 * (var(--sidenote-padding) + var(--sidenote-border-width)));
    left: calc(-1 * (var(--sidenote-padding) + var(--sidenote-border-width)));
}
#markdownBody .sidenote:hover::after,
#markdownBody .sidenote.targeted::after,
#markdownBody .sidenote.displaced::after,
#markdownBody .sidenote.highlighted::after {
    border: 3px double $border-colour;
}

#markdownBody .sidenote.cut-off::before {
    content: "…";
    position: absolute;
    border: 1px dotted $border-colour;
    top: calc(100% - 1px);
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
    z-index: 2;
    background-color: $background-colour;
    padding: 0.125em 0.5em 0.5em 0.5em;
    font-size: 1.25em;
    font-weight: 600;
    line-height: 0.5;
    pointer-events: none;
}
#markdownBody .sidenote.cut-off:hover::before {
    visibility: hidden;
}
#markdownBody .sidenote.cut-off.hide-more-indicator::before {
    visibility: hidden;
}

/*=-------------------=*/
/*= Sidenote wrappers =*/
/*=-------------------=*/

/*  Outer wrapper (scrolls).
    */
.sidenote-outer-wrapper {
    max-height: 600px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    border-style: dotted;
    border-color: $border-colour;
    border-width: 1px 0;
}
#markdownBody .sidenote:hover .sidenote-outer-wrapper,
#markdownBody .sidenote.displaced .sidenote-outer-wrapper,
#markdownBody .sidenote.highlighted .sidenote-outer-wrapper {
    /*  1px for inner border; 1px for minimum distance from edge. */
    max-height: calc(100vh - (2em + 2 * (var(--sidenote-padding) + var(--sidenote-border-width) + 1px + 1px)));
}
#markdownBody .sidenote.cut-off .sidenote-outer-wrapper {
    overflow-y: scroll;
    overscroll-behavior: none;
    width: 100%;
    padding: 0 0.5em 0 0;
}

/*  Scroll bar styles (for WebKit/Chromium).
    */
#markdownBody .sidenote.cut-off .sidenote-outer-wrapper::-webkit-scrollbar {
    width: 12px;
}
#markdownBody .sidenote.cut-off .sidenote-outer-wrapper::-webkit-scrollbar-thumb {
    background-image: var(--GW-checkerboard-scrollbar-background-image);
    background-size: 2px;
    box-shadow:
        0 0 0 2px $background-colour inset;
}
#markdownBody .sidenote.cut-off .sidenote-outer-wrapper::-webkit-scrollbar-thumb:hover {
    background-image: var(--GW-checkerboard-scrollbar-hover-background-image);
}

/*  Scroll bar styles (for Firefox).
    */
#markdownBody .sidenote.cut-off .sidenote-outer-wrapper {
    scrollbar-color: var(--GW-sidenote-scrollbar-thumb-color) $background-colour;
}
#markdownBody .sidenote.cut-off .sidenote-outer-wrapper:hover {
    scrollbar-color: var(--GW-sidenote-scrollbar-thumb-hover-color) $background-colour;
}

/*  Inner wrapper (does not scroll; stretches to height of content).
    */
.sidenote-inner-wrapper {
    position: relative;
    margin: 0.5em 0;
}

/*  Ensure content is interactive.
    */
.sidenote-inner-wrapper > * {
    position: relative;
    z-index: 1;
}

/*=--------------------------------------=*/
/*= Sidenote contents layout corrections =*/
/*=--------------------------------------=*/

#markdownBody .sidenote {
    --base-block-spacing: 0.2em;
}

#markdownBody .sidenote .list {
    --list-left-padding: 1.75em;
}

#markdownBody .sidenote li p {
    text-align: left;
}

#markdownBody .sidenote .footnote-back {
    vertical-align: text-top;
}

#markdownBody .sidenote blockquote > * {
    line-height: calc(1.25 / (var(--blockquote-font-size-scaling-factor) * 0.85))
}

/*=-------------------------------=*/
/*= Sidenote self-links (numbers) =*/
/*=-------------------------------=*/

#markdownBody .sidenote > .sidenote-self-link {
    font-weight: $font-bold;
    font-family: $super;
    position: absolute;
    top: -1px;
    background-image: none;
    border-style: dotted;
    border-color: $border-colour;
    border-width: 1px 1px 0px 1px;
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
#markdownBody #sidenote-column-left .sidenote > .sidenote-self-link {
    right: 0;
}
#markdownBody #sidenote-column-right .sidenote > .sidenote-self-link {
    left: 0;
}

#markdownBody .sidenote.targeted > .sidenote-self-link,
#markdownBody .sidenote > .sidenote-self-link:hover {
    border-width: 3px;
    border-style: double;
    top: -3px;
}
#markdownBody #sidenote-column-left .sidenote.targeted > .sidenote-self-link,
#markdownBody #sidenote-column-left .sidenote > .sidenote-self-link:hover {
    right: -2px;
}
#markdownBody #sidenote-column-right .sidenote.targeted > .sidenote-self-link,
#markdownBody #sidenote-column-right .sidenote > .sidenote-self-link:hover {
    left: -2px;
}

#markdownBody .sidenote.targeted > .sidenote-self-link::before,
#markdownBody .sidenote > .sidenote-self-link:hover::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -3px;
    width: calc(50vw - (935px / 2 + 96px) + 10px);
    max-width: 550px;
    border-bottom: 3px double $border-colour;
}
#markdownBody #sidenote-column-left .sidenote.targeted > .sidenote-self-link::before,
#markdownBody #sidenote-column-left .sidenote > .sidenote-self-link:hover::before {
    right: -1px;
}
#markdownBody #sidenote-column-right .sidenote.targeted > .sidenote-self-link::before,
#markdownBody #sidenote-column-right .sidenote > .sidenote-self-link:hover::before {
    left: -1px;
}
