$background-colour: #FFFFF8;
$text-colour: #2A2A29;
$link-colour: #555553;
$hover-colour: #80807c;
$border-colour: #555553;
$secondary-background-colour: #F8F8FF;
$secondary-text-colour: #4a4a4c;
$secondary-hover-colour: #dfdfe5;
$secondary-border-colour: #c6c6cc;
$highlight-colour: #ad1c42;
$highlight-colour-secondary: #8e3b51;
