// Define generic font families for fallbacks
$source-sans: "Source Sans 3", sans-serif;
$source-serif: "Source Serif 4", serif;
$source-code: "Source Code Pro", monospace;

// Kings Caslon
@font-face {
  font-family: "Kings Caslon Regular";
  src: url("../fonts/Kings%20Caslon/KingsCaslonStdRg.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kings Caslon Bold";
  src: url("../fonts/Kings%20Caslon/KingsCaslonStdBd.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kings Caslon Italic";
  src: url("../fonts/Kings%20Caslon/KingsCaslonStdIt.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Kings Caslon Bold Italic";
  src: url("../fonts/Kings%20Caslon/KingsCaslonStdBdIt.ttf") format("truetype"); // Use the Bold file for Bold Italic too
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Kings Caslon Display Regular";
  src: url("../fonts/Kings%20Caslon/KingsCaslonDisplayStdRg.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kings Caslon Display Italic";
  src: url("../fonts/Kings%20Caslon/KingsCaslonDisplayStdIt.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "ACaslon Expert";
  src: url("../fonts/Adobe%20Caslon%20Regular%20Expert.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Founders Caslon (incomplete)
@font-face {
  font-family: "FCaslon Twelve Std";
  src: url("../fonts/FCaslonTwelveITCStd-Roman.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FCaslon Twelve Std Italic";
  src: url("../fonts/FCaslonTwelveITCStd-Italic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "FCaslon Twelve Std Bold";
  src: url("../fonts/FCaslonTwelveITCStd-Roman.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// Irvin Heading
@font-face {
  font-family: "Irvin Heading";
  src: url("../fonts/Irvin-Heading.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Courier Std
@font-face {
  font-family: "Courier Std";
  src: url("../fonts/CourierStd.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Courier Std Bold"; // Or "Courier Bold"
  src: url("../fonts/CourierStd-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// Flyerfonts Release
@font-face {
  font-family: "Flyerfonts Release";
  src: url("../fonts/Flyerfonts-Release.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Flyerfonts Malfunction
@font-face {
  font-family: "Flyerfonts Release";
  src: url("../fonts/Flyerfonts-Malfunction.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// American Typewriter
@font-face {
  font-family: "American Typewriter";
  src: url("../fonts/AmericanTypewriterStd-Med.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

//Neutraface
@font-face {
  font-family: "Neutra Text Book";
  src: url("../fonts/Neutraface/Neutra%20Text%20Basic/NeutraTextBook.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neutra Text Book Italic";
  src: url("../fonts/Neutraface/Neutra%20Text%20Basic/NeutraTextBookItalic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Neutra Text Bold";
  src: url("../fonts/Neutraface/Neutra%20Text%20Basic/NeutraTextBold.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neutra Text Light Italic";
  src: url("../fonts/Neutraface/Neutra%20Text%20Basic/NeutraTextLightItalic.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

